// TODO: Should it be somewhere generally accessible, like @utils/locales?
export const LOCALE_ENGLISH_MAPPING = {
    'bg-bg': 'en-eu',
    'cs-cz': 'en-eu',
    'de-de': 'en-eu',
    'en-us': 'en-us',
    'es-es': 'en-eu',
    'es-mx': 'en-us',
    'fr-fr': 'en-eu',
    'he-il': 'en-us',
    'hi-in': 'en-gb',
    'hu-hu': 'en-eu',
    'id-id': 'en-sg',
    'it-it': 'en-eu',
    'ja-jp': 'en-sg',
    'ko-kr': 'en-sg',
    'nl-nl': 'en-eu',
    'pl-pl': 'en-eu',
    'pt-br': 'en-us',
    'pt-pt': 'en-eu',
    'ro-ro': 'en-eu',
    'sv-se': 'en-eu',
    'th-th': 'en-sg',
    'tr-tr': 'en-eu',
    'vi-vn': 'en-sg',
    'zh-cn': 'en-sg',
    'zh-tw': 'en-sg',
};
