<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { CARD_FIELDS_SUBSET, SECTION_ID_CPC } from '@model/const/blog';
import { assertHttpErrors } from '@utils/api-response';
import { getTextDirection, getOgImage, getHtmlLang } from '@utils/html-meta';
import BlogAuthorsComponent from './component.vue';

const MORE_POSTS_ON_PAGE = 4;

const EXPERT_FIELDS_SUBSET = [
    'id',
    'slug',
    'name',
    'photo',
    'position',
    'description',
    'link_linked_in',
    'link_twitter',
    'link_website',
];

export default Vue.extend({
    name: 'BlogAuthorsContainer',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;

        const morePostsRequest = new ProductAPIQueryBuilder('morePosts')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, MORE_POSTS_ON_PAGE)
            .toObject();

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'section_id', 'slug', 'title'])
            .toObject();

        const authorsRequest = new ProductAPIQueryBuilder('authors')
            .setEntityPath('/api/blog/authors')
            .setCustomParam('has', { sections: [SECTION_ID_CPC] })
            .setOutputOnly(EXPERT_FIELDS_SUBSET)
            .setLocales([locale])
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('sectionTranslationsList')
            .setEntityPath('/api/blog/sections/translations/')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(['locale'])
            .setPaginate(1, 20)
            .toObject();

        await Promise.all([
            this.$store.dispatch('locales/getLocaleData', { locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: morePostsRequest }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('blog/getEntity', { request: authorsRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
        ]);

        const authorsState = this.$store.state.blog.authors;

        assertHttpErrors([
            { entity: authorsState, throwIfEmpty: true },
            { entity: this.$store.state.blog.categories },
            { entity: this.$store.state.blog.sectionTranslationsList },
        ]);

        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings: any = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const ogImage = getOgImage(`@${uiStrings.cpcOgImage}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = `${uiStrings.cpcExperts} | Acronis`;
            const description = uiStrings.homeMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },
    },

    render(h) {
        return h(BlogAuthorsComponent);
    },
});
</script>
