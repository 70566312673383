<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { CARD_FIELDS_SUBSET, SECTION_ID_BLOG } from '@model/const/blog';
import { assertHttpErrors } from '@utils/api-response';
import { getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import BlogSearchComponent from './component.vue';

const CARDS_NUMBER_ON_PAGE = 12;
const SEARCH_RESULTS_ON_PAGE = 6;
const ENTITY_MAX_LENGTH = 50;

export default Vue.extend({
    name: 'BlogSearchContainer',

    async serverPrefetch(): Promise<void> {
        // ==== Init
        const locale = this.$route.params.locale;

        // ==== Get all entities
        const tagsRequest = new ProductAPIQueryBuilder('tags')
            .setEntityPath('/api/blog/tags/')
            .setLocales([locale])
            .setOutputOnly(['slug', 'title', 'posts_count'])
            .setCustomParam('process-macros', '1')
            .setPaginate(1, ENTITY_MAX_LENGTH)
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('sectionTranslationsList')
            .setEntityPath('/api/blog/sections/translations/')
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .setOutputOnly(['locale'])
            .setPaginate(1, ENTITY_MAX_LENGTH)
            .toObject();

        const audiencesRequest = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/core/pages/audiences/')
            .setOutputOnly(['id', 'name'])
            .setPaginate(1, ENTITY_MAX_LENGTH)
            .toObject();

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .setOutputOnly(['id', 'title', 'section'])
            .setPaginate(1, ENTITY_MAX_LENGTH)
            .toObject();

        const latestCardsRequest = new ProductAPIQueryBuilder('latestCards')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '<>', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, CARDS_NUMBER_ON_PAGE)
            .toObject();

        await Promise.all([
            this.$store.dispatch('locales/getLocaleData', { locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('products/getProducts', locale),
            this.$store.dispatch('blog/getEntity', { request: latestCardsRequest }),
            this.$store.dispatch('blog/getEntity', { request: tagsRequest }),
            this.$store.dispatch('blog/getEntity', { request: audiencesRequest }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.blog.latestCards },
            { entity: this.$store.state.blog.tags },
            { entity: this.$store.state.blog.sectionTranslationsList },
            { entity: this.$store.state.blog.audiences },
            { entity: this.$store.state.blog.assets },
            { entity: this.$store.state.blog.categories },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings: any = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const ogImage = getOgImage(`@${uiStrings.ogImage}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = uiStrings.homeMetaTitle;
            const description = uiStrings.homeMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },

        deleteCards() {
            this.$store.dispatch('blog/deleteEntity', 'cards');
        },

        async goToPage(page: number, sortingBy: string, filter: Record<string, number>): Promise<boolean> {
            const sortingParams = new ProductAPIQueryBuilder('sortingBy')
                .addSort(sortingBy === 'recent' ? 'translation.published_at' : 'translation.views_count', 'desc')
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc');

            const hasCriteria: Record<string, number[]> = {};

            if (filter?.productId) hasCriteria.products = [filter.productId];
            if (filter?.categoryId) hasCriteria.categories = [filter.categoryId];

            const request = new ProductAPIQueryBuilder('cards')
                .setEntityPath('/api/blog/posts/')
                .setLocales([this.$route.params.locale])
                .setOutputOnly(CARD_FIELDS_SUBSET)
                .setPaginate(page, SEARCH_RESULTS_ON_PAGE)
                .addMatchesAll('page_audience_id', '=', filter.audienceId ? filter.audienceId.toString() : null)
                .addMatchesAll('asset_id', '=', filter.assetId ? filter.assetId.toString() : null)
                .setCustomParam('has', Object.keys(hasCriteria).length ? hasCriteria : null)
                .mergeParams(sortingParams.toObject().params)
                .toObject();

            await this.$store.dispatch('blog/getEntity', { request });
            return this.$store.state.blog.cards.httpStatus === StatusCodes.OK;
        },
    },

    render(h) {
        const props = {
            dispatchDeleteCards: this.deleteCards,
            dispatchGoToPage: this.goToPage,
        };

        return h(BlogSearchComponent, { props });
    },
});
</script>
